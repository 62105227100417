import React from 'react';
import { ParentDiv, LoadingText, SpinnerLoader, InnerSpinnerLoader } from './styled';

const LoadingMessage = () => (
  <ParentDiv>
    <SpinnerLoader>
      <InnerSpinnerLoader />
    </SpinnerLoader>
    <LoadingText>Loading Player. If it takes longer than 15 seconds, please reload the page.</LoadingText>
  </ParentDiv>
);

export { LoadingMessage };
