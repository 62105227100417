import styled, { keyframes } from 'styled-components';

export const spin = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

export const ParentDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  z-index: -1;
`;

export const SpinnerLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 0.25rem solid rgba(0, 0, 0, 0.2);
  border-top-color: #663399;
  animation: ${spin} 1s infinite linear;
`;

export const InnerSpinnerLoader = styled.div`
  border-radius: 50%;
  width: 90%;
  height: 90%;
  border: 0.25rem solid transparent;
  border-top-color: #292828;
  animation: ${spin} 10s infinite linear;
`;

export const LoadingText = styled.div`
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`;
