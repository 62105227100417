import styled from 'styled-components';

export const Main = styled.div`
  margin: 148px 0 0 0;
	min-height: calc(100vh - 56px - 64px - 97px);
	
	@media only screen and (max-width: 648px) {
		margin: 80px 0 0 0;
	}
`;
